import {
  DailyWorkReportKey,
  DailyWorkReportTimeStamp
} from "@/models/dailyWorkReport/DailyWorkReportKey";

export const RepairTableHeaders = [
  { text: "作業年月日", value: "workDate" },
  { text: "更新日時", value: "updateTs" },
  { text: "作業者", value: "userId" },
  { text: "受注番号", value: "acceptNo" },
  { text: "受注番号品 完了", value: "successFalg" },
  { text: "前工程作業番号", value: "preProcessNo" },
  { text: "枝番", value: "branchNumber" },
  { text: "品名", value: "itemName" },
  { text: "サイズ", value: "size" },
  { text: "数量明細(m)", value: "suryoMeisai" },
  { text: "不良内容(1)", value: "defectDetails1" },
  { text: "不良内容(2)", value: "defectDetails2" },
  { text: "不良内容(3)", value: "defectDetails3" },
  { text: "修理内容(1)", value: "repairDetails1" },
  { text: "修理内容(2)", value: "repairDetails2" },
  { text: "修理内容(3)", value: "repairDetails3" },
  { text: "備考", value: "biko" }
] as const;

export class RepairTable {
  workDate: string;
  updateTs: string | null;
  userId: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  preProcessNo: string | null;
  branchNumber: number | null;
  itemName: string | null;
  size: string | null;
  suryoMeisai: string | null;
  defectDetails1: string | null;
  defectDetails2: string | null;
  defectDetails3: string | null;
  repairDetails1: string | null;
  repairDetails2: string | null;
  repairDetails3: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;

  constructor(props: {
    workDate: string;
    userId: string | null;
    acceptNo: string | null;
    successFalg: string | null;
    preProcessNo: string | null;
    branchNumber: number | null;
    itemName: string | null;
    size: string | null;
    suryoMeisai: string | null;
    defectDetails1: string | null;
    defectDetails2: string | null;
    defectDetails3: string | null;
    repairDetails1: string | null;
    repairDetails2: string | null;
    repairDetails3: string | null;
    biko: string | null;
    denno: number | null;
    gyono: number | null;
  }) {
    this.workDate = props.workDate;
    this.updateTs = null;
    this.userId = props.userId;
    this.acceptNo = props.acceptNo;
    this.successFalg = props.successFalg;
    this.preProcessNo = props.preProcessNo;
    this.branchNumber = props.branchNumber;
    this.itemName = props.itemName;
    this.size = props.size;
    this.suryoMeisai = props.suryoMeisai;
    this.defectDetails1 = props.defectDetails1;
    this.defectDetails2 = props.defectDetails2;
    this.defectDetails3 = props.defectDetails3;
    this.repairDetails1 = props.repairDetails1;
    this.repairDetails2 = props.repairDetails2;
    this.repairDetails3 = props.repairDetails3;
    this.biko = props.biko;
    this.denno = props.denno;
    this.gyono = props.gyono;
  }
}

export const RepairText = {
  workDate: { text: "作業年月日", value: "workDate" },
  serialNo: { text: "シリアル番号", value: "serialNo" },
  userId: { text: "作業者", value: "userId" },
  acceptNo: { text: "受注番号", value: "acceptNo" },
  successFalg: { text: "受注番号品 完了", value: "successFalg" },
  preProcessNo: { text: "前工程作業番号", value: "preProcessNo" },
  branchNumber: { text: "枝番", value: "branchNumber" },
  itemName: { text: "品名", value: "itemName" },
  size: { text: "サイズ", value: "size" },
  suryoMeisai: { text: "数量明細(m)", value: "suryoMeisai" },
  defectDetails1: { text: "不良内容(1)", value: "defectDetails1" },
  defectDetails2: { text: "不良内容(2)", value: "defectDetails2" },
  defectDetails3: { text: "不良内容(3)", value: "defectDetails3" },
  repairDetails1: { text: "修理内容(1)", value: "repairDetails1" },
  repairDetails2: { text: "修理内容(2)", value: "repairDetails2" },
  repairDetails3: { text: "修理内容(3)", value: "repairDetails3" },
  biko: { text: "備考", value: "biko" }
} as const;

export class Repair extends DailyWorkReportKey
  implements DailyWorkReportTimeStamp {
  postCode: string | null;
  userId: string | null;
  acceptNo: string | null;
  successFalg: string | null;
  preProcessNo: string | null;
  branchNumber: number | null;
  itemName: string | null;
  size: string | null;
  suryoMeisai: string | null;
  defectDetails1: string | null;
  defectDetails2: string | null;
  defectDetails3: string | null;
  repairDetails1: string | null;
  repairDetails2: string | null;
  repairDetails3: string | null;
  biko: string | null;
  denno: number | null;
  gyono: number | null;
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;

  constructor(_props: Repair);
  constructor(_props: DailyWorkReportKey);
  constructor(_props: Repair | DailyWorkReportKey) {
    super(_props);
    if (_props instanceof Repair) {
      this.postCode = _props.postCode;
      this.userId = _props.userId;
      this.acceptNo = _props.acceptNo;
      this.successFalg = _props.successFalg;
      this.preProcessNo = _props.preProcessNo;
      this.branchNumber = _props.branchNumber;
      this.itemName = _props.itemName;
      this.size = _props.size;
      this.suryoMeisai = _props.suryoMeisai;
      this.defectDetails1 = _props.defectDetails1;
      this.defectDetails2 = _props.defectDetails2;
      this.defectDetails3 = _props.defectDetails3;
      this.repairDetails1 = _props.repairDetails1;
      this.repairDetails2 = _props.repairDetails2;
      this.repairDetails3 = _props.repairDetails3;
      this.biko = _props.biko;
      this.denno = _props.denno;
      this.gyono = _props.gyono;
      this.createUser = _props.createUser;
      this.createTs = _props.createTs;
      this.updateUser = _props.updateUser;
      this.updateTs = _props.updateTs;
      return;
    }
    this.postCode = "REPAIR";
    this.userId = null;
    this.acceptNo = null;
    this.successFalg = "0";
    this.preProcessNo = null;
    this.branchNumber = null;
    this.itemName = null;
    this.size = null;
    this.suryoMeisai = null;
    this.defectDetails1 = null;
    this.defectDetails2 = null;
    this.defectDetails3 = null;
    this.repairDetails1 = null;
    this.repairDetails2 = null;
    this.repairDetails3 = null;
    this.biko = null;
    this.denno = null;
    this.gyono = null;
    this.createUser = null;
    this.createTs = null;
    this.updateUser = null;
    this.updateTs = null;
  }
}
